"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommutingTripService = void 0;
var _models = require("../models");
var _http = require("./http");
var _user = require("./user");
class CommutingTripService {
  static async createCommutingTrip(_ref) {
    let {
      employeeId: employee,
      homeId: home,
      workId: work
    } = _ref;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.post('v1', `/commuting_trips`, [], [], JSON.stringify({
        employee,
        home,
        work
      }));
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][createCommutingTrip]', err);
      throw err;
    }
  }
  static async getCommutingTrips(_ref2) {
    let {
      employeeId
    } = _ref2;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const {
        results
      } = await _http.HttpService.get('v1', `/commuting_trips`, [{
        key: 'page',
        value: 1
      }, {
        key: 'page_size',
        value: 100
      }, {
        key: 'employee_id',
        value: employeeId
      }, {
        key: 'enabled',
        value: true
      }]);
      return results.map(parseCommutingTrip);
    } catch (err) {
      console.error('[CommutingTripService][getCommutingTrips]', err);
      throw err;
    }
  }
  static async getCommutingTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.get('v1', `/commuting_trips/${id}`);
      return parseCommutingTrip(data);
    } catch (err) {
      console.error('[CommutingTripService][getCommutingTrip]', err);
      throw err;
    }
  }
  static async removeCommutingTrip(id) {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      await _http.HttpService.delete('v1', `/commuting_trips/${id}`);
      return true;
    } catch (err) {
      console.error('[CommutingTripService][removeCommutingTrip]', err);
      throw err;
    }
  }
}
exports.CommutingTripService = CommutingTripService;
function parseCommutingTrip(_ref3) {
  let {
    id,
    title,
    home: homeId,
    home_address,
    home_point,
    work: workId,
    work_address,
    work_point,
    bicycle_portions
  } = _ref3;
  return new _models.CommutingTrip(id, title || `Trajet vélotaf ${id}`, new _models.Place(homeId, home_point, {
    primaryText: home_address
  }), new _models.Place(workId, work_point, {
    primaryText: work_address
  }), (bicycle_portions || []).map(_ref4 => {
    let {
      distance_in_meters_start_end: distanceStartEnd,
      distance_in_meters_end_start: distanceEndStart
    } = _ref4;
    return {
      distanceStartEnd,
      distanceEndStart
    };
  }));
}